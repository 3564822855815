import { ShareService } from '../service/share.service';
interface LanguageDetailValue {
    languageKey: string;
    value: string;
}

interface DataItem {
    keyName: string;
    lang: string;
    languageDetailValues: LanguageDetailValue[];
}

export function getTextByLanguage(data: DataItem[] | any, shareService: ShareService): { [key: string]: string } {
    let result: { [key: string]: string } = {};

    if (!Array.isArray(data)) {
        console.error('Expected data to be an array, but received:', data);
        return result;
    }

    const lang = shareService.getLanguageStr() === 'vi' ? 'vi' : 'en';
    
    data.forEach((item: any) => {
        const languageDetail = item.languageDetailValues.find((detail: any) => detail.languageKey === lang);
        if (languageDetail) {
            result[item.keyName] = languageDetail.value;
        }
    });

    return result
}

export function getUrlBySlug(slug: string, baseUrl: string, lang: string): string {
    return `${baseUrl}/${lang}/${slug}`;
  }